import styled from "styled-components";

export const RoomsPageContainer = styled.div`
  height: 100vh;
  width: 100wv;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  /* justify-content: space-between; */
  padding: 16px;
`;

export const BackButton = styled.div`
  height: 40px;
  width: 40px;
  background: transparent;
  border: 2px solid white;
  cursor: pointer;
  position: absolute;
  top: 100px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 24px;
    line-height: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 24px;
  color: white;
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TeamScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  img {
    width: 35px;
  }
`;

export const RoomsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 800px;
  gap: 20px;
  padding-bottom: 24px;
  overflow: auto;
`;

export const RoomBox = styled.div`
  display: flex;
  height: 70px;
  background: rgba(255, 255, 255, 0.1);
  align-items: center;
  padding-left: 16px;
`;
