import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ImagesContainer,
  Text,
  WinnerPageContainer,
  WinnerTitle,
} from "./WinnerPageStyled";

export const WinnerPage = () => {
  const location = useLocation();
  const { winnerTeam, gameUniqueId, language } = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/feedback-page", {
        state: { gameUniqueId: gameUniqueId, language: language },
      });
    }, 4000);
  }, []);

  return (
    <WinnerPageContainer>
      <ImagesContainer>
        <img
          style={{
            width: "55px",
            height: "55px",
            // position: "absolute",
            // top: 0,
            // left: 160,
          }}
          src="/images/winnerBadge.png"
          alt="badge"
        />
        <img
          style={{
            width: "220px",
            height: "200px",
            marginLeft: "20px",
            // position: "absolute",
            // top: 30,
          }}
          src={
            winnerTeam === "YELLOW"
              ? "/images/yellowLife.png"
              : "/images/greenLife.png"
          }
          alt="winner"
        />
      </ImagesContainer>
      <WinnerTitle
        style={{ color: winnerTeam === "YELLOW" ? "#EC9A2F" : "#3E9166" }}
      >
        {language == "RO" ? "Echipa " : "Team"}{" "}
        {winnerTeam === "YELLOW"
          ? `${language == "RO" ? "Portocalie" : "Orange"}`
          : `${language == "RO" ? "Verde" : "Green"}`}
      </WinnerTitle>
      <Text>{language == "RO" ? "A câștigat" : "Won"}</Text>
    </WinnerPageContainer>
  );
};
