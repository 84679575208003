import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ButtonsContainer,
  ContainerTitle,
  DecideScoreContainer,
  DecideWinnerPageContainer,
  GameInfoTitle,
  GameTypeTitle,
  HeaderTitlesContainer,
  NextRoundButton,
  TeamWinnerContainer,
} from "./DecideWinnerPageStyled";
import { SaveWinner } from "../../api/api";

type ScoreStatus = {
  yellowTeam: number;
  draw: number;
  greenTeam: number;
};

export const DecideWinnerPage = () => {
  const uuid = localStorage.getItem("uuid");
  const location = useLocation();
  const { gameId, gameType, gameName, roomName, language } = location.state;

  const [selectedWinner, setSelectedWinner] = useState<
    keyof ScoreStatus | null
  >(null);
  const [scoreStatus, setScoreStatus] = useState<ScoreStatus>({
    yellowTeam: 0,
    draw: 0,
    greenTeam: 0,
  });

  const convertGameType = (gameType: string) => {
    if (gameType === "Best_of_3") {
      return 2;
    } else if (gameType === "Best_of_5") {
      return 3;
    } else {
      return 1;
    }
  };

  const parseGameType = (gameType: string) => {
    if (gameType === "Best_of_3") {
      return "3";
    } else if (gameType === "Best_of_5") {
      return "5";
    } else {
      return "1";
    }
  };

  const determineWinner = () => {
    const { yellowTeam, draw, greenTeam } = scoreStatus;

    if (yellowTeam === greenTeam) {
      return "No winner yet";
    } else if (yellowTeam >= convertGameType(gameType) && yellowTeam > greenTeam) {
      return "YELLOW";
    } else if (greenTeam >= convertGameType(gameType) && greenTeam > yellowTeam) {
      return "GREEN";
    } else if (draw >= convertGameType(gameType)) {
      return "No winner yet";
    } else {
      return "No winner yet";
    }
  };

  const handleYellowTeamClick = () => {
    setSelectedWinner("yellowTeam");
  };

  const handleDrawClick = () => {
    setSelectedWinner("draw");
  };

  const handleGreenTeamClick = () => {
    setSelectedWinner("greenTeam");
  };

  const handleNextRoundClick = () => {
    if (selectedWinner) {
      if (selectedWinner === "draw") {
        setScoreStatus((prevState) => ({
          ...prevState,
          ['yellowTeam']: prevState['yellowTeam'] + 1,
        }));
        setScoreStatus((prevState) => ({
          ...prevState,
          ['greenTeam']: prevState['greenTeam'] + 1,
        }));
      } else {
        setScoreStatus((prevState) => ({
          ...prevState,
          [selectedWinner]: prevState[selectedWinner] + 1,
        }));
      }

      setSelectedWinner(null);
    }

    //Login to end the game and device winner
    //
    // ---- >>
  };

  const teamWinnerContainerStyle = (team: string) => ({
    backgroundColor:
      selectedWinner === team ? "rgba(255, 255, 255, 0.2)" : "transparent",
    border: selectedWinner === team ? "2px solid #3381FF" : "none",
  });

  useEffect(() => {
    const winner = determineWinner();
    if (winner !== "No winner yet") {
      if (uuid && gameId != null) {
        SaveWinner(uuid, gameId, winner).then(() => {
          setTimeout(() => {
            navigate("/winner-page", {
              state: {
                winnerTeam: winner,
                gameUniqueId: gameId,
                language: language,
              },
            });
          }, 500);
        });
      }
    }
  }, [scoreStatus]);

  const navigate = useNavigate();
  return (
    <DecideWinnerPageContainer>
      <HeaderTitlesContainer>
        <ContainerTitle>
          {language == "RO"
            ? "Adaugă Câștigătorul Rundei"
            : "Add the round winner"}
        </ContainerTitle>
        <GameInfoTitle>
          {language == "RO" ? "Joc" : "Game"} {gameId}/11 |{" "}
          {language == "RO" ? "Camera" : "Room"} {gameId}
        </GameInfoTitle>
        <p style={{ fontSize: "20px" }}>{gameName}</p>
        <GameTypeTitle>Best of {parseGameType(gameType)}</GameTypeTitle>
      </HeaderTitlesContainer>
      <DecideScoreContainer>
        <TeamWinnerContainer
          onClick={() => handleYellowTeamClick()}
          style={teamWinnerContainerStyle("yellowTeam")}
        >
          <img src="/images/yellowLife.png" alt="yellow" />
          <p>{language == "RO" ? "Echipa Portocalie" : "Team Orange"}</p>
          <span>{scoreStatus.yellowTeam}</span>
        </TeamWinnerContainer>
        <TeamWinnerContainer
          onClick={() => handleDrawClick()}
          style={teamWinnerContainerStyle("draw")}
        >
          <img src="/images/draw.png" alt="draw" />
          <p>{language == "RO" ? "Remiza" : "Draw"}</p>
          <span>{scoreStatus.draw}</span>
        </TeamWinnerContainer>
        <TeamWinnerContainer
          onClick={() => handleGreenTeamClick()}
          style={teamWinnerContainerStyle("greenTeam")}
        >
          <img src="/images/greenLife.png" alt="green" />
          <p>{language == "RO" ? "Echipa Verde" : "Team Green"}</p>
          <span>{scoreStatus.greenTeam}</span>
        </TeamWinnerContainer>
      </DecideScoreContainer>
      <ButtonsContainer>
        <NextRoundButton
          onClick={() => {
            handleNextRoundClick();
          }}
        >
          {language == "RO" ? "Runda Urmatoare" : "Next Round"}
        </NextRoundButton>
      </ButtonsContainer>
    </DecideWinnerPageContainer>
  );
};
