import styled from "styled-components";

export const WinnerPageContainer = styled.div`
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const WinnerTitle = styled.p`
  font-size: 34px;
  font-weight: 700;
`;
export const Text = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`;
