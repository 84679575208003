import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EmojiContainer, FeedbackPageContainer } from "./FeedbackPageStyled";
import { FeedbackResponse } from "../../api/api";

export const FeedbackPage = () => {
  const location = useLocation();
  const { gameUniqueId, language } = location.state;
  const uuid = localStorage.getItem("uuid");
  const [feedback, setFeedBack] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (uuid && feedback !== "") {
      FeedbackResponse(uuid, gameUniqueId, feedback);
      navigate("/home");
    }
  }, [feedback]);
  return (
    <FeedbackPageContainer>
      <p>
        {language == "RO"
          ? "Cum ti s-a parut jocul?"
          : "How would you like the game?"}
      </p>
      <EmojiContainer>
        <img
          src="/images/goodReview.png"
          alt="good"
          onClick={() => {
            setFeedBack("GOOD");
          }}
        />

        <img
          src="/images/neutralReview.png"
          alt="neutral"
          onClick={() => {
            setFeedBack("NEUTRAL");
          }}
        />

        <img
          src="/images/badReview.png"
          alt="bad"
          onClick={() => {
            setFeedBack("BAD");
          }}
        />
      </EmojiContainer>
      <span
        onClick={() => {
          setFeedBack("SKIP");
        }}
      >
        {language == "RO" ? "Sari peste" : "Skip"}
      </span>
    </FeedbackPageContainer>
  );
};
