import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChooseBettingPoints } from "../../api/api";
import { BettingPointsType } from "../../api/responseTypes";
import { colors } from "../../colors";
import {
  BettingInformation,
  BettingPageContainer,
  BettingPointsContainer,
  ButtonsContainer,
  NextTeamButton,
  TeamContainer,
  TeamName,
  TeamsBox,
} from "./BettingPageStyled";

export const BettingPage = () => {
  const location = useLocation();
  const { gameInfo, alreadyUsedPoints, language } = location.state;

  const sessionId = localStorage.getItem("uuid");
  const [scorePoints, setScorePoints] = useState(
    Array(11)
      .fill("")
      .map((_, i) => i + 1)
  );

  const [usedPoints, setUsedPoints] = useState<number[]>(
    alreadyUsedPoints.yellowUsedPoints
  );
  const [selectedPoint, setSelectedPoint] = useState<number | null>(null);
  const navigate = useNavigate();
  const [teamSelection, setTeamSelection] = useState<BettingPointsType>({
    yellowTeamPoints: null,
    greenTeamPoints: null,
  });
  const [currentPlayer, setCurrentPlayer] = useState(1);

  const handleNextTeamButtonClick = () => {
    if (selectedPoint !== null) {
      if (teamSelection.yellowTeamPoints === null) {
        setTeamSelection((prevState) => ({
          ...prevState,
          yellowTeamPoints: selectedPoint,
        }));

        setUsedPoints(alreadyUsedPoints.greenUsedPoints);
      } else if (teamSelection.greenTeamPoints === null) {
        setTeamSelection((prevState) => ({
          ...prevState,
          greenTeamPoints: selectedPoint,
        }));
        setUsedPoints([]);
      }

      setSelectedPoint(null);
      setCurrentPlayer(currentPlayer + 1);
    }
  };

  const convertString = (gameType: string) => {
    if (gameType === "Best_of_3") {
      return "Best of 3";
    } else if (gameType === "Best_of_5") {
      return "Best of 5";
    } else {
      return "Best of 1";
    }
  };

  useEffect(() => {
    if (
      teamSelection.greenTeamPoints !== null &&
      teamSelection.yellowTeamPoints != null
    ) {
      if (sessionId) {
        ChooseBettingPoints(sessionId, gameInfo.gameId, teamSelection).then(
          () => {
            setTimeout(() => {
              navigate("/decide-winner", {
                state: {
                  gameId: gameInfo.gameId,
                  gameType: gameInfo.gameType,
                  gameName: gameInfo.gameName,
                  roomName: gameInfo.roomName,
                  language: language,
                },
              });
            }, 2000);
          }
        );
      }
    }
  }, [teamSelection.greenTeamPoints, teamSelection.yellowTeamPoints]);

  return (
    <BettingPageContainer>
      <BettingInformation>
        <p style={{ color: "white", fontSize: "34px" }}>{gameInfo.gameName}</p>
        <p style={{ color: `${colors.textBlue}` }}>
          {convertString(gameInfo.gameType)}
        </p>
        <p style={{ color: "white" }}>
          {language == "RO" ? "Selecteaza punctele" : "Select points"}
        </p>
      </BettingInformation>
      <TeamsBox>
        {" "}
        <TeamContainer>
          <img src="/images/yellowLife.png" alt="yellow life" />
          <TeamName className={currentPlayer === 1 ? "yellow-turn" : ""}>
            {language === "RO" ? "Echipa Portocalie" : "Team Orange"}
          </TeamName>
          <span>{teamSelection.yellowTeamPoints}</span>
        </TeamContainer>
        <TeamContainer>
          <img src="/images/greenLife.png" alt="green life" />
          <TeamName className={currentPlayer === 2 ? "green-turn" : ""}>
            {language === "RO" ? "Echipa Verde" : "Team Green"}
          </TeamName>
          <span>{teamSelection.greenTeamPoints}</span>
        </TeamContainer>
      </TeamsBox>
      <BettingPointsContainer>
        {scorePoints.map((v, i) => {
          const isIndexInAdditionalArray = usedPoints.includes(v);

          return (
            <div
              onClick={() => {
                if (!isIndexInAdditionalArray) {
                  setSelectedPoint(v);
                }
              }}
              key={i}
              style={{
                width: "56px",
                height: "56px",
                background: `${
                  v === selectedPoint
                    ? `${colors.textBlue}`
                    : isIndexInAdditionalArray
                    ? "red"
                    : "transparent"
                }`,
                border: "2px solid white",
                display: "flex",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "700",
                cursor: isIndexInAdditionalArray ? "not-allowed" : "pointer",
                pointerEvents: isIndexInAdditionalArray ? "none" : "auto",
              }}
            >
              {v}
            </div>
          );
        })}
      </BettingPointsContainer>
      <ButtonsContainer>
        <NextTeamButton
          onClick={() => {
            handleNextTeamButtonClick();
            // if (sessionId)
            //   ChooseBettingPoints(sessionId, gameId, teamSelection);
          }}
        >
          {language == "RO" ? "Urmatoarea Echipa" : "Next Team"}
        </NextTeamButton>
      </ButtonsContainer>
    </BettingPageContainer>
  );
};
