import styled from "styled-components";
import { colors } from "../../colors";

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.primaryDarkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  overflow: auto;
`;

export const LogoContainer = styled.div`
  padding-top: 50px;
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ScoreBoardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  gap: 30px;
`;

export const TeamScore = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  font-size: 32px;
  justify-content: flex-end;
  gap: 10px;
  p {
    margin: 0;
  }
  img {
    width: 70px;
  }
`;
export const Separator = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;
  height: 100%;
  font-size: 32px;
  p {
    margin: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  transform: skew(-0.03turn, 0deg);
`;
export const ScanButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 68px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-size: 24px;
  p {
    transform: skew(0.03turn, 0deg);
  }
`;

export const SeeMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 68px;
  background-color: ${colors.primaryOrange};
  color: white;
  border: none;
  font-size: 24px;
  p {
    transform: skew(0.03turn, 0deg);
  }
`;
