import axiosLib from "axios";
import { BettingPointsType, GameTypeBody, StatusType } from "./responseTypes";

const axios = axiosLib.create({
  baseURL: "https://backend.versusgames.ro/api",
});

export const SigningToGame = (uuid: string) => {
  axios
    .post(`/versus/universe/new/universe?uuid=${uuid}`)
    .then((res) => res.data)
    .catch((e) => e);
};

export const GetGameStatus = (uuid: string): Promise<StatusType> => {
  return axios
    .get(`/versus/universe/${uuid}/status`)
    .then((res) => res.data)
    .catch((e) => e);
};

export const StartNewGame = (uuid: string, gameInfo: GameTypeBody) => {
  return axios
    .post(`/versus/universe/${uuid}/new/game/`, gameInfo)
    .then((res) => res.data);
};

export const ChooseBettingPoints = (
  uuid: string,
  gameId: number,
  bettingPoints: BettingPointsType
) => {
  return axios
    .post(
      `/versus/universe/${uuid}/game/${gameId}/claim?yellowTeamPoints=${bettingPoints.yellowTeamPoints}&greenTeamPoints=${bettingPoints.greenTeamPoints}`
    )
    .then((res) => res.data);
};

export const SaveWinner = (uuid: string, gameId: number, winner: string) => {
  return axios
    .post(`/versus/universe/${uuid}/game/${gameId}/winner?winner=${winner}`)
    .then((res) => res.data);
};
export const FeedbackResponse = (
  uuid: string,
  gameId: number,
  feedback: string
) => {
  return axios
    .post(
      `/versus/universe/${uuid}/game/${gameId}/feedback?feedback=${feedback}`
    )
    .then((res) => res.data);
};
