import React, { useState } from "react";
import {
  ButtonsContainer,
  Input,
  LoggingUserContainer,
  LogoContainer,
  SubmitButton,
} from "./LogginUserPageStyled";
import { SigningToGame } from "../../api/api";
import { useNavigate } from "react-router-dom";

export const LogginUserPage = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  const validate = (date: any, code: any) => {
    if (code.length !== 6) {
      return false;
    }
    const day = parseInt(code.substring(0, 2), 10);
    const month = parseInt(code.substring(2, 4), 10);
    const number = parseInt(code.substring(4, 6), 10);

    if (
      day === date.getDate() &&
      month === date.getMonth() + 1 &&
      number < 100
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (validate(new Date(), inputValue)) {
      localStorage.setItem("uuid", inputValue);
      SigningToGame(inputValue);
      navigate("/home");
    } else {
      // NEED TO BE SETTED A STATE
      console.log("Fail");
    }
  };

  return (
    <LoggingUserContainer>
      <LogoContainer>
        <img src="/images/versusLogo.png" alt="logo" />
      </LogoContainer>
      <Input
        placeholder="12X4YZ"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <ButtonsContainer>
        <SubmitButton onClick={onSubmit}>Submit</SubmitButton>
      </ButtonsContainer>
    </LoggingUserContainer>
  );
};
