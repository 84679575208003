export const games = [
  {
    gameId: 1,
    camera: "Camera 1",
    gameType: "Best of 3",
    gameName: "Incarca Trenul",
  },
  {
    gameId: 2,
    camera: "Camera 2",
    gameType: "Best of 1",
    gameName: "Geo Trivia",
  },
  {
    gameId: 3,
    camera: "Camera 3",
    gameType: "Best of 5",
    gameName: "Cantareste corect",
  },
  {
    gameId: 4,
    camera: "Camera 4",
    gameType: "Best of 3",
    gameName: "Masa in echilibru",
  },
  {
    gameId: 5,
    camera: "Camera 5",
    gameType: "Best of 3",
    gameName: "LED Lava",
  },
  {
    gameId: 6,
    camera: "Camera 6",
    gameType: "Best of 1",
    gameName: "Trivia",
  },
  {
    gameId: 7,
    camera: "Camera 7",
    gameType: "Best of 3",
    gameName: "Piscina cu bureti",
  },
  {
    gameId: 8,
    camera: "Camera 8",
    gameType: "Best of 3",
    gameName: "Catch it",
  },
  {
    gameId: 9,
    camera: "Camera 9",
    gameType: "Best of 3",
    gameName: "Wood Hockey",
  },
  {
    gameId: 10,
    camera: "Camera 10",
    gameType: "Best of 3",
    gameName: "Biliardul piratilor",
  },
  {
    gameId: 11,
    camera: "Camera 11",
    gameType: "Best of 1",
    gameName: "Poolball",
  },
];
