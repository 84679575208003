import styled from "styled-components";
import { colors } from "../../colors";

export const DecideWinnerPageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-around;
  p {
    margin: 5px;
  }
`;

export const HeaderTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ContainerTitle = styled.p`
  font-size: 24px;
  color: white;
`;
export const GameInfoTitle = styled.p`
  font-size: 24px;
  color: ${colors.textBlue};
`;

export const GameTypeTitle = styled.p`
  color: white;
`;

export const DecideScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 5px;
`;

export const TeamWinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 170px;
  min-height: 108px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  p {
    font-size: 20px;
  }
  img {
    width: 80px;
    object-fit: contain;
  }
  span {
    position: absolute;
    top: 22px;
    font-weight: 700;
    font-size: 24px;
    color: black;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  transform: skew(-0.03turn, 0deg);
`;
export const NextRoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 68px;
  background-color: ${colors.primaryOrange};
  color: white;
  border: none;
  font-size: 24px;
  p {
    transform: skew(0.03turn, 0deg);
  }
`;
