import "./App.css";
import { Home } from "./components/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QrPage } from "./components/QRPage/QRPage";
import { RoomsPage } from "./components/RoomsPage/RoomsPage";
import { BettingPage } from "./components/BettingPage/BettingPage";
import { FeedbackPage } from "./components/FeedbackPage/FeedbackPage";
import { DecideWinnerPage } from "./components/DecideWinnerPage/DecideWinnerPage";
import { WinnerPage } from "./components/WinnerPage/WinnerPage";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import { LogginUserPage } from "./components/LoggingUserPage/LogginUserPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoadingScreen />} />
        <Route path="/logging-user" element={<LogginUserPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/qr-scan" element={<QrPage />} />
        <Route path="/rooms-page" element={<RoomsPage />} />
        <Route path="/betting-page" element={<BettingPage />} />
        <Route path="/decide-winner" element={<DecideWinnerPage />} />
        <Route path="/winner-page" element={<WinnerPage />} />
        <Route path="/feedback-page" element={<FeedbackPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
