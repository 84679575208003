import React from "react";
import { games } from "../../gamesData/games";
import {
  BackButton,
  Header,
  RoomBox,
  RoomsContainer,
  RoomsPageContainer,
  ScoreContainer,
  TeamScore,
  Title,
} from "./RoomsPageStyled";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";

export const RoomsPage = () => {
  const location = useLocation();
  const { gameStatus, language } = location.state;

  const navigate = useNavigate();
  return (
    <RoomsPageContainer>
      <BackButton>
        <IoIosArrowBack onClick={() => navigate("/home")} />
      </BackButton>
      <Header>
        <Title>
          {language == "RO" ? "Universul Versus" : "Versus Universe"}
        </Title>
        <ScoreContainer>
          <TeamScore>
            <img src="/images/yellowLife.png" alt="yellow" />
            <p>{gameStatus.yellowTeamPoints}</p>
          </TeamScore>
          <p>/</p>
          <TeamScore>
            <img src="/images/greenLife.png" alt="green" />
            <p>{gameStatus.greenTeamPoints}</p>
          </TeamScore>
        </ScoreContainer>
      </Header>
      <RoomsContainer>
        {games.map((item, index) => {
          const isYellowWinner =
            gameStatus.yellowTeamWins[index + 1] !== undefined;
          const isGreenWinner =
            gameStatus.greenTeamWins[index + 1] !== undefined;
          const style = {
            backgroundColor: isYellowWinner
              ? "#EC9A2F"
              : isGreenWinner
              ? "#3E9166"
              : "rgba(255, 255, 255, 0.1)",
          };
          return (
            <RoomBox key={index} style={style}>
              {language == "RO" ? "Camera" : "Room"} {item.gameId}
            </RoomBox>
          );
        })}
      </RoomsContainer>
    </RoomsPageContainer>
  );
};
