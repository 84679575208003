import styled from "styled-components";

export const FeedbackPageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-around;
  p {
    font-size: 24px;
  }
  span {
    font-size: 24px;
  }
`;

export const EmojiContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 40px;
  span {
    font-size: 50px;
  }
  img {
    height: 100px;
    width: 100px;
  }
`;
