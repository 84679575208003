import React, { useEffect } from "react";
import {
  LoadingContainer,
  LoadingScreenLogoContainer,
} from "./LoadingScreenStyled";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SigningToGame } from "../../api/api";

export const LoadingScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // const uuid = uuidv4();

    // localStorage.setItem("uuid", uuid);
    // SigningToGame(uuid);
    setTimeout(() => {
      navigate("/logging-user");
    }, 3000);
  }, []);
  return (
    <LoadingContainer>
      <LoadingScreenLogoContainer>
        <img src="/images/versusLogo.png" alt="logo" />
        {/* <video
              src="/images/animatedLogo.mp4"
              autoPlay={true}
              loop
              preload="auto"
              playsInline
              muted
              style={{ background: "transparent" }}
            /> */}
      </LoadingScreenLogoContainer>
    </LoadingContainer>
  );
};
