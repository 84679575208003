import styled from "styled-components";
import { colors } from "../../colors";

export const LoggingUserContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.primaryDarkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  overflow: auto;
`;

export const LogoContainer = styled.div`
  padding-top: 50px;
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  transform: skew(-0.03turn, 0deg);
`;

export const Input = styled.input`
  text-align: center;
  height: 60px;
  width: 305px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 24px;
  transform: skew(-0.03turn, 0deg);
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 68px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-size: 24px;
  p {
    transform: skew(0.03turn, 0deg);
  }
`;
