import styled, { keyframes } from "styled-components";
import { colors } from "../../colors";

export const BettingPageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const BettingInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 5px;
  p {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  transform: skew(-0.03turn, 0deg);
`;
export const NextTeamButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 68px;
  background-color: ${colors.primaryOrange};
  color: white;
  border: none;
  font-size: 24px;
  p {
    transform: skew(0.03turn, 0deg);
  }
`;

export const TeamsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;

export const TeamContainer = styled.div`
  display: flex;
  color: white;
  align-items: center;
  gap: 10px;
  padding-left: 30px;
  p {
    font-size: 24px;
    margin: 0;
  }
  img {
    width: 50px;
    object-fit: contain;
  }
`;

export const BettingPointsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  padding: 35px;
`;

const pulseYellow = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const pulseGreen = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const TeamName = styled.p`
  &.yellow-turn {
    animation: ${pulseYellow} 1s infinite;
  }

  &.green-turn {
    animation: ${pulseGreen} 1s infinite;
  }
`;
