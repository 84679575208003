import { useNavigate } from "react-router-dom";
import {
  ButtonsContainer,
  HomeContainer,
  LogoContainer,
  ScanButton,
  ScoreBoardContainer,
  SeeMoreButton,
  Separator,
  TeamScore,
} from "./HomeStyled";
import { useEffect, useState } from "react";
import { GetGameStatus } from "../../api/api";
import { StatusType } from "../../api/responseTypes";
import AnimatedNumbers from "react-animated-numbers";

const LOCAL_STORAGE_KEY = "selectedLanguage";

export const Home = () => {
  const navigate = useNavigate();
  const uuid = localStorage.getItem("uuid");
  const [statusData, setStatusData] = useState<StatusType>({
    uuid: "",
    yellowTeamWins: {},
    greenTeamWins: {},
    yellowUsedPoints: [],
    greenUsedPoints: [],
    yellowTeamPoints: 0,
    greenTeamPoints: 0,
    finished: false,
  });

  const [currentLanguage, setCurrentLanguage] = useState<string>(() => {
    return localStorage.getItem(LOCAL_STORAGE_KEY) || "EN";
  });

  const toggleLanguage = () => {
    if (currentLanguage === "EN") {
      setCurrentLanguage("RO");
    } else {
      setCurrentLanguage("EN");
    }
  };
  useEffect(() => {
    if (uuid)
      GetGameStatus(uuid).then((data) => {
        setStatusData(data);
      });
    localStorage.setItem(LOCAL_STORAGE_KEY, currentLanguage);
  }, [currentLanguage]);

  return (
    <HomeContainer>
      <div
        onClick={() => toggleLanguage()}
        style={{
          right: "10px",
          top: "10px",
          position: "absolute",
          padding: "10px",
          color: "white",
          borderRadius: "50%",
          border: "1px solid white",
        }}
      >
        {currentLanguage === "RO" ? "EN" : "RO"}
      </div>
      <LogoContainer>
        <img src="/images/versusLogo.png" alt="logo" />
      </LogoContainer>
      <ScoreBoardContainer>
        <TeamScore>
          <img src="/images/yellowLife.png" alt="yellow life"></img>
          <AnimatedNumbers
            includeComma
            animateToNumber={statusData.yellowTeamPoints}
            fontStyle={{ fontSize: 40 }}
            locale="en-US"
            configs={[
              { mass: 1, tension: 220, friction: 100 },
              { mass: 1, tension: 180, friction: 130 },
              { mass: 1, tension: 280, friction: 90 },
              { mass: 1, tension: 180, friction: 135 },
              { mass: 1, tension: 260, friction: 100 },
              { mass: 1, tension: 210, friction: 180 },
            ]}
          ></AnimatedNumbers>
        </TeamScore>
        <Separator>
          <p>/</p>
        </Separator>
        <TeamScore>
          <img src="/images/greenLife.png" alt="green life"></img>
          <AnimatedNumbers
            includeComma
            animateToNumber={statusData.greenTeamPoints}
            fontStyle={{ fontSize: 40 }}
            locale="en-US"
            configs={[
              { mass: 1, tension: 220, friction: 100 },
              { mass: 1, tension: 180, friction: 130 },
              { mass: 1, tension: 280, friction: 90 },
              { mass: 1, tension: 180, friction: 135 },
              { mass: 1, tension: 260, friction: 100 },
              { mass: 1, tension: 210, friction: 180 },
            ]}
          ></AnimatedNumbers>
        </TeamScore>
      </ScoreBoardContainer>
      <ButtonsContainer>
        <ScanButton
          onClick={() => {
            // handleNav("/qr-scan");
            navigate("/qr-scan", {
              state: {
                alreadyUsedPoints: {
                  yellowUsedPoints: statusData.yellowUsedPoints,
                  greenUsedPoints: statusData.greenUsedPoints,
                },
                language: currentLanguage,
                yellowTeamWins: statusData.yellowTeamWins,
                greenTeamWins: statusData.greenTeamWins,
              },
            });
          }}
        >
          <p>{currentLanguage == "RO" ? "Scaneaza QR" : "Scan QR"}</p>
        </ScanButton>
        <SeeMoreButton
          onClick={() => {
            navigate("/rooms-page", {
              state: { gameStatus: statusData, language: currentLanguage },
            });
          }}
        >
          <p>{currentLanguage == "RO" ? "Vezi jocurile" : "See games"}</p>
        </SeeMoreButton>
      </ButtonsContainer>
    </HomeContainer>
  );
};
