import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useLocation, useNavigate } from "react-router-dom";
import { StartNewGame } from "../../api/api";
import {
  LoadingScreenLogoContainer,
  QrPageContainer,
  QrPortraitContainer,
} from "./QRPageStyled";

export const QrPage = (props: any) => {
  const location = useLocation();
  const { alreadyUsedPoints, language, yellowTeamWins, greenTeamWins } =
    location.state;
  const [data, setData] = useState("No result");
  const [alreadySelected, setAlreadySelected] = useState(false);
  const uuid = localStorage.getItem("uuid");

  const navigate = useNavigate();

  const handleNavToGame = (data: any) => {
    if (
      Object.keys(yellowTeamWins).includes(
        JSON.parse(data).gameId.toString()
      ) ||
      Object.keys(greenTeamWins).includes(JSON.parse(data).gameId.toString())
    ) {
      setAlreadySelected(true);
      return;
    } else if (uuid && data !== "No result") {
      StartNewGame(uuid, JSON.parse(data)).then(() => {
        window.location.reload();
      });

      navigate(`/betting-page`, {
        state: {
          gameInfo: JSON.parse(data),
          alreadyUsedPoints: alreadyUsedPoints,
          language: language,
        },
      });
      setAlreadySelected(true);
    }
  };

  return (
    <QrPageContainer>
      <QrPortraitContainer>
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result: any, error: any) => {
            if (!!result) {
              setData(result?.text);
              handleNavToGame(result?.text);
            }

            if (!!error) {
              console.info(error);
            }
          }}
          containerStyle={{ width: "100%" }}
        />
      </QrPortraitContainer>

      <p style={{ margin: 0, width: "60%", textAlign: "center" }}>
        {alreadySelected
          ? `${
              language == "RO"
                ? `Te-ai mai jucat acest joc. Scaneaza un cod QR diferit`
                : `You already played this game. Scan a different QR code`
            }`
          : null}
      </p>
      {alreadySelected ? (
        <p
          onClick={() => navigate("/home")}
          style={{
            margin: 0,
            border: "2px solid white",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          {" "}
          {language == "RO"
            ? "Inapoi la pagina de start"
            : "Back to Home Screen"}
        </p>
      ) : null}

      <LoadingScreenLogoContainer>
        <img src="/images/versusLogo.png" alt="versus" />
      </LoadingScreenLogoContainer>
    </QrPageContainer>
  );
};
