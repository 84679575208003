import styled, { keyframes } from "styled-components";
import { colors } from "../../colors";

const animateHeight = keyframes`
  0%, 100% {
    height: 250px;
    
  }
  50% {
    height: 300px;
    
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.primaryDarkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingScreenLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 350px;
  height: 400px;
  img {
    object-fit: contain;
  }
  video {
    object-fit: contain;
    width: 400px;
    height: 400px;
  }
  animation: ${animateHeight} 3s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
`;
