import styled from "styled-components";
import { colors } from "../../colors";

export const QrPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.primaryDarkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  color: white;
  justify-content: space-around;
`;

export const QrPortraitContainer = styled.div`
  width: 350px;
  height: 300px;
`;

export const LoadingScreenLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;
  height: 200px;
  img {
    object-fit: contain;
  }
`;
